import React from 'react'
import styled, { css } from 'styled-components'
import { object, func, bool, string, number, oneOfType } from 'prop-types'
import { stripUnit, rgba } from 'polished'

import {
  primaryColor,
  borderColor,
  greyLight,
  black,
  white,
  offWhite,
} from 'styles/colors'
import {
  borderRadius,
  inputHeight,
  inputHeightDesktop,
  animationTime,
  animationCurve,
} from 'styles/variables'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

import Icon from 'components/Icon'
import Label from 'components/Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const SelectWrap = styled.div`
  position: relative;
`

const Select = styled.select`
  display: block;
  height: ${inputHeight};
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  width: 100%;
  resize: none;
  vertical-align: top;
  outline: 0;
  padding: 0 ${spacing.medium};
  box-shadow: 0 1px 2px 0 ${rgba(black, 0.05)};
  padding-right: 48px;
  cursor: pointer;
  transition: all ${animationTime} ${animationCurve};

  ${media.medium`
    height: ${inputHeightDesktop};
  `};

  ${props =>
    props.size === 'small' &&
    css`
      height: ${stripUnit(inputHeight) * 0.8 + 'px'};

      ${media.medium`
        height: ${stripUnit(inputHeightDesktop) * 0.8 + 'px'};
      `};
    `}

  &:focus {
    background-color: ${white};
    border-color: ${primaryColor};
    box-shadow: 0 0 0 3px ${rgba(primaryColor, 0.2)};
  }

  &[disabled] {
    color: ${greyLight};
    pointer-events: none;
    background-color: ${rgba(offWhite, 0.75)};
    user-select: none;
    opacity: 1; /* Overwrite iOS styles */
  }
`

const IconWrap = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  right: ${spacing.medium};
  transform: translateY(-50%);
  pointer-events: none;
`

const Dropdown = ({
  options,
  name,
  onChange,
  onBlur,
  value,
  disabled,
  size,
  label,
  required,
  ...rest
}) => (
  <Container {...rest}>
    {label && <Label>{label}</Label>}
    <SelectWrap>
      <Select
        size={size}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        disabled={disabled}
        required={required}
      >
        {options.map(option => {
          const { label = option, value = option } = option || {}

          return <option value={value}>{label}</option>
        })}
      </Select>
      <IconWrap>
        <Icon icon='dropdown' size='tiny' />
      </IconWrap>
    </SelectWrap>
  </Container>
)

Dropdown.propTypes = {
  options: object.isRequired,
  onChange: func.isRequired,
  onBlur: func,
  disabled: bool,
  label: string,
  name: string.isRequired,
  value: oneOfType([string, number]).isRequired,
}

export default Dropdown
