import React, { useState } from 'react'
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import { css } from 'styled-components'

import logoPaypal from '../assets/images/carte-cadeau/logo-paypal.png'
import Input from 'components/Input'
import Button from 'components/button/Button'
import Dropdown from '../components/Dropdown'

const GiftCard = ({ data }) => {
  const intl = useIntl()
  const [values, setValues] = useState({ hdm3: '', os0: '' })

  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data

  const handleOnChange = e => {
    const { name, value } = e.target

    setValues({ ...values, [name]: value })
  }

  return (
    <Layout>
      <MetaTags
        title={`${intl.formatMessage({ id: 'giftCard' })} | Hotel Griffintown`}
      />
      <Header
        eyebrow={intl.formatMessage({ id: 'form' })}
        heading={intl.formatMessage({ id: 'giftCard' })}
        fluid={fluid}
        objectFit='contain'
      />
      <Section>
        <SectionBlock>
          <Wrapper size='narrow'>
            <GridRow>
              <GridItem xs='1-1' lg='3-4'>
                <FormattedHTMLMessage id='giftCard.intro' />
              </GridItem>
            </GridRow>
          </Wrapper>
        </SectionBlock>

        <SectionBlock>
          <Wrapper size='narrow'>
            <form
              action='https://www.paypal.com/cgi-bin/webscr'
              method='post'
              target='_blank'
              name='paypal'
            >
              <Input type='hidden' name='cmd' value='_s-xclick' />
              <Input
                type='hidden'
                name='hosted_button_id'
                value='GTXK2UTE72VUQ'
              />
              <Input type='hidden' name='on0' value='Montant' />
              <Input type='hidden' name='on1' value='Offert à' />
              <Input type='hidden' name='on2' value='De la part de' />
              <Input
                type='hidden'
                name='on3'
                value='De quelle façon désirez-vous faire parvenir votre carte-cadeau ?'
              />
              <Input
                type='hidden'
                name='on4'
                value='Information sur la carte cadeau'
              />
              <Input
                type='hidden'
                id='os4'
                name='os4'
                value={`NOTE IMPORTANTE: Veuillez allouer 24h avant que nous traitions votre commande. Prénom:${values.hdm3} Nom:${values.hdm4} Adresse:${values.hdm5} Ville:${values.hdm6} Code Postal:${values.hdm7}`}
              />

              <Input type='hidden' name='on8' value='Numéro de téléphone' />
              <Input type='hidden' name='on9' value='Votre courriel' />
              <Input type='hidden' name='currency_code' value='CAD' />

              <GridRow>
                <GridItem xs='1-1' sm='1-1' md='1-2'>
                  <GridRow>
                    <GridItem xs='1-1'>
                      <Heading
                        size={4}
                        variant='sans'
                        css={`
                          margin-top: 2em !important;
                          margin-bottom: 0.25em !important;
                        `}
                      >
                        {intl.formatMessage({ id: 'shippingAddress' })}
                      </Heading>
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        name='hdm3'
                        label={intl.formatMessage({ id: 'firstName' })}
                        value={values.hdm3}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'lastName' })}
                        name='hdm4'
                        value={values.hdm4}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'address' })}
                        name='hdm5'
                        value={values.hdm5}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'city' })}
                        name='hdm6'
                        value={values.hdm6}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'postalCode' })}
                        name='hdm7'
                        value={values.hdm7}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>
                </GridItem>

                <GridItem xs='1-1' sm='1-1' md='1-2'>
                  <GridRow>
                    <GridItem xs='1-1'>
                      <Heading
                        size={4}
                        variant='sans'
                        css={`
                          margin-top: 2em !important;
                          margin-bottom: 0.25em !important;
                        `}
                      >
                        {intl.formatMessage({ id: 'CCInformations' })}
                      </Heading>
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'offeredTo' })}
                        name='os1'
                        value={values.os1}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'offeredFrom' })}
                        name='os2'
                        value={values.os2}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Dropdown
                        name='os3'
                        label={intl.formatMessage({
                          id: 'howToCollectGiftCard',
                        })}
                        onChange={handleOnChange}
                        value={values.os3}
                        required
                        options={[
                          '',
                          intl.formatMessage({ id: 'byEmail' }),
                          intl.formatMessage({ id: 'byMail' }),
                          intl.formatMessage({ id: 'onSite' }),
                        ]}
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Heading
                        size={4}
                        variant='sans'
                        css={`
                          margin-top: 2em !important;
                          margin-bottom: 0.25em !important;
                        `}
                      >
                        {intl.formatMessage({ id: 'toContactYou' })}
                      </Heading>
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'phoneNumber' })}
                        name='os8'
                        value={values.os8}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Input
                        label={intl.formatMessage({ id: 'yourEmail' })}
                        name='os9'
                        value={values.os9}
                        onChange={handleOnChange}
                        required
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <Dropdown
                        label={intl.formatMessage({ id: 'ChooseAmount' })}
                        name='os0'
                        onChange={handleOnChange}
                        value={values.os0}
                        required
                        options={[
                          { value: '', label: '' },
                          { value: 100, label: '$100,00 CAD' },
                          { value: 150, label: '$150,00 CAD' },
                          { value: 200, label: '$200,00 CAD' },
                          { value: 300, label: '$300,00 CAD' },
                          { value: 400, label: '$400,00 CAD' },
                          { value: 500, label: '$500,00 CAD' },
                          { value: 600, label: '$600,00 CAD' },
                          { value: 700, label: '$700,00 CAD' },
                          { value: 800, label: '$800,00 CAD' },
                          { value: 900, label: '$900,00 CAD' },
                        ]}
                      />
                    </GridItem>
                  </GridRow>

                  <GridRow
                    css={css`
                      margin-top: 2em;
                      padding-bottom: 1em;
                    `}
                  >
                    <GridItem xs='1-1'>
                      <p>{intl.formatMessage({ id: '24hProccessOrder' })}</p>
                    </GridItem>
                  </GridRow>

                  <GridRow
                    css={css`
                      margin-top: 2em;
                      padding-bottom: 1em;
                    `}
                  >
                    <GridItem xs='1-1'>
                      <img
                        alt=''
                        border='0'
                        src='https://www.paypalobjects.com/fr_CA/i/scr/pixel.gif'
                        width='1'
                        height='1'
                      />
                      <Button type='submit'>
                        {intl.formatMessage({ id: 'validateAndPayGiftCard' })}
                      </Button>
                    </GridItem>
                  </GridRow>

                  <GridRow>
                    <GridItem xs='1-1'>
                      <img src={logoPaypal} alt='Paypal' />
                    </GridItem>
                  </GridRow>
                </GridItem>
              </GridRow>
            </form>
          </Wrapper>
        </SectionBlock>
      </Section>
    </Layout>
  )
}

export default GiftCard

export const query = graphql`
  query GiftCardPage {
    headerImage: file(
      name: { eq: "GriffintownHotel-CertificatCadeau-ImgSite-Right" }
    ) {
      childImageSharp {
        fluid(maxHeight: 590, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
